/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// @import '~lightgallery/scss/lightgallery';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
@font-face {
    font-family: 'Gilroy-Light';
    src: url('./assets/fonts/Gilroy-Light.otf');
}

@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('./assets/fonts/Gilroy-ExtraBold.otf');
}

* {
    // font-family: 'Poppins', sans-serif;
    // font-family: 'Gilroy-ExtraBold', sans-serif;
    // font-family: Roboto,"sans-serif";
    // font-size: 16px;
    // background: #fff;
    // font-family: 'Roboto','Arial', sans-serif;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212121;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.manrope__font {
    font-family: 'Manrope', sans-serif;
}

.sans-font {
    font-family: 'Open Sans', sans-serif;
}

.roboto-font {
    font-family: 'Roboto', sans-serif;
}

//Remove input box increase arrow
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.primary {
    color: var(--primary-text);
}

.primary-border {
    border-color: var(--secondary-bg) !important;
}

.alternative {
    color: var(--alternative);
}

.bg-white {
    background-color: #fff;
}

// ion-item:hover,ion-button:hover{
//   --ion-item-color : var(--secondary-bg);
// }
// ion-button:hover{
//   --background : var(--secondary-light-bg );
// }
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.cart-buttons,
.image,
.main-image {
    user-select: none;
    -webkit-user-select: none;
}

ion-fab-button {
    color: var(--white);
    --background: var(--secondary-bg);
}

ion-ripple-effect {
    color: var(--primary-bg);
}

ion-segment-button {
    --indicator-color: var(--primary-bg) !important;
    min-width: unset
}

ion-tab-button {
    // --color: var(--ion-color-medium);
    --color-selected: var(--secondary-bg);
    --color: var(--secondary-bg);
    &.tab-selected {
        // background-color: var(--primary-bg);
        ion-icon {
            font-size: 32px;
            color: var(--secondary-bg);
        }
        ion-label {
            font-weight: 700;
            color: var(--secondary-bg);
        }
    }
}

ion-refresher-content {
    color: white !important;
}

ion-slides {
    --bullet-background: white;
    --bullet-background-active: var(--secondary-bg) !important;
}

ion-checkbox,
ion-radio {
    --background-checked: var(--secondary-bg);
    --border-color-checked: transparent;
    --border-color: var(--secondary-text);
    --color-checked: var(--secondary-bg);
    --border-width: 1px;
}

.main-width {
    width: 95%;
    margin: 0 auto;
    min-height: 100%;
}

a {
    text-decoration: none;
    color: var(--primary-text);
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.p-5 {
    padding: 5px;
}

.m-5 {
    margin: 5px;
}

.p-10 {
    padding: 10px;
}

.mouse {
    cursor: pointer;
}

.fnt-14 {
    font-size: 14px;
}

.fnt-15 {
    font-size: 15px;
}

.fnt-13 {
    font-size: 13px;
}

.fnt-12 {
    font-size: 12px;
}

.fnt-11 {
    font-size: 11px;
}

.fnt-10 {
    font-size: 10px;
}

.fnt-16 {
    font-size: 16px;
}

.fnt-20 {
    font-size: 20px;
    font-weight: 600;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.user-select {
    user-select: none;
    -webkit-user-select: none;
}

.fnt-600 {
    font-weight: 600;
}

.star {
    color: #fec650;
    font-weight: 600;
}

.prices {
    span,
    p {
        color: var(--alternative);
        font-weight: 600;
    }
}

.close-icon {
    font-size: 20px;
}

.close-icon:hover {
    color: red;
}

.flex-3 {
    flex: 0 0 33.333%;
}

.flex-4 {
    flex: 0 0 25%;
}

.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-2 {
    gap: 2px;
}

.webkit-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--line);
    -webkit-box-orient: vertical;
}

.d__flex {
    display: flex;
    align-items: center;
}

.flex-w {
    flex-wrap: wrap;
}

.just-c {
    justify-content: center;
}

.just-s {
    justify-content: space-between;
}

.just-end {
    justify-content: flex-end;
}

.just-a {
    justify-content: space-around;
}

.txt-r {
    text-align: right;
}

.txt-l {
    text-align: left;
}

.txt-c {
    text-align: center;
}

.ov {
    overflow: visible;
}

.ov-x {
    overflow-x: auto;
}

.mx-10 {
    margin: 0 10px;
}

.my-5 {
    margin: 5px 0;
}

img {
    user-select: none;
}

.my-10 {
    margin: 10px 0;
}

.mx-15 {
    margin: 0 15px;
}

.my-15 {
    margin: 15px 0;
}

.mb-8 {
    margin-bottom: 8px;
}

.py-5 {
    padding: 5px 0;
}

.px-b-10 {
    padding: 0 10px 10px 10px;
}

.py-15 {
    padding: 15px 0;
}

.py-10 {
    padding: 10px 0;
}

.px-15 {
    padding: 0 15px
}

.px-10 {
    padding: 0 10px;
}

.l-t {
    text-decoration: line-through;
}

.nw {
    white-space: nowrap;
}

.mini-100 {
    min-height: 100%;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-5 {
    margin-right: 5px;
}

.mt-5 {
    margin-top: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}

.h-100 {
    height: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.bg-white {
    background-color: #fff;
}

.active-heart {
    color: red !important;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.position-relative {
    position: relative;
}

.border {
    border: 1px solid var(--border);
}

.Breadcrumb {
    font-size: 15px;
}

.Bread-crumb {
    display: flex;
    align-items: center;
    font-size: 15px;
    li {
        font-size: 15px;
    }
    a,
    ion-icon {
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        color: var(--secondary-text);
    }
    a:hover,
    .active-bread {
        color: var(--primary-text);
    }
}

ul {
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
        margin: 0;
    }
}

.border-b {
    border-bottom: 1px solid var(--border);
}

.border-t {
    border-top: 1px solid var(--border);
}

ion-header {
    &.header-md:after {
        background: none;
    }
    ion-toolbar {
        --min-height: 50px;
        background-color: var(--primary-bg);
        ion-icon,
        ion-back-button,
        ion-title {
            color: #fff;
        }
    }
    // .back-btn{
    //     font-size: 24px;
    //     margin-left: 10px;
    //     color:  #70757a;;
    // }
    // border-bottom:  1px solid #dadce0;
    box-shadow: 0 3px 6px 0 rgb(40 47 54 / 10%);
}

// ion-item{
//   --background : #e8e8e833 !important;
// }
// ion-item:hover{
//   --ion-item-color : var(--primary-bg);
// }
ion-input,
ion-select {
    height: 38px;
}

@media (min-width: 768px) {
    .btn-lg {
        height: 50px;
        --color: var(--white) !important;
        color: var(--white) !important;
        background-color: var(--secondary-bg);
        border-radius: 3px;
    }
}

@media (max-width: 768px) {
    ion-input,
    ion-select {
        // height: 38px; 
        font-size: 13px;
    }
    ion-button {
        height: 42px;
        font-size: 15px;
        font-weight: 500;
    }
    .main-width {
        width: 100%;
        margin: 0;
    }
    .attribute-popup,
    .quick-view-popup {
        --height: 60% !important;
        --width: 85%;
        --border-radius: 5px;
    }
    .oops-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 20%;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        ion-icon {
            font-size: 150px;
        }
    }
    // .cat-price {
    //   font-size: 14px;
    // }
    .cat-title {
        font-weight: 500;
        white-space: nowrap;
    }
}

.actionsheet-popup {
    --height: 100% !important;
    padding-top: 199px !important;
    border-radius: 20px 20px 0 0 !important;
    .ion-page {
        display: unset !important;
    }
}

@media (min-width: 768px) {
    .segment {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: sticky;
        top: 0;
        background: #fff !important;
        z-index: 1;
        .btn {
            width: 50%;
            text-align: center;
            padding-bottom: 8px;
            font-weight: 500;
            color: var(--secondary-text);
            padding: 10px;
            border-bottom: 3px solid;
            border-color: transparent;
        }
        .active {
            border-color: var(--secondary-bg);
            color: var(--primary-text);
        }
    }
}

@media only screen and (min-width: 768px) {
    // .sc-ion-modal-md-h {
    //   --width: 490px !important;
    //   --height: 565px !important;
    // }
    ion-menu {
        --min-width: 500px;
    }
    .sharing-popup {
        --width: 500px;
        --height: 280px;
    }
    .order-detail-popup {
        --width: 1000px !important;
        --height: 600px !important;
        --border-radius: 11px;
    }
    .quick-view-popup {
        --width: 1060px !important;
        --height: 580px !important;
        --border-radius: 11px;
    }
    .auth-popup {
        // --width: 495px !important;
        // --height: 510px !important;
        --width: 1000px !important;
        --height: 575px !important;
        --border-radius: 11px;
    }
    .address-popup {
        // --width: 800px !important;
        --width: 550px !important;
        --height: 605px !important;
        --border-radius: 5px !important;
    }
    .attribute-popup {
        --width: 420px !important;
        --height: 453px !important;
        --border-radius: 5px !important;
    }
    .review-poup {
        --height: 530px !important;
        --width: 500px !important;
        --padding-left: 750px !important;
        --padding-top: 135px !important;
        // --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
        .sc-ion-modal-md-h {
            padding-left: 750px !important;
            padding-top: 135px !important;
        }
    }
}

ion-spinner {
    height: 17px;
    width: 27px;
}

@media only screen and (min-width: 768px) {}

@media only screen and (max-width: 768px) {
    .cart-buttons {
        .__button {
            height: 27px !important;
            width: 27px !important;
        }
    }
}

.added {
    background: var(--secondary-bg);
    border-radius: 2px;
    padding: 5px 10px;
}

.not__added {
    font-size: 12px;
    font-weight: 700;
}

//Cart-button
.cart-buttons {
    .cart__button {
        display: flex;
        align-items: center;
        // margin-right: 10px;
        .__button {
            background: var(--secondary-bg);
            // background: red;
            font-size: 25px;
            border-radius: 5px;
            height: 30px;
            width: 30px;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            --padding-start: 0;
            --padding-end: 0;
            --padding-bottom: 0;
            --padding-top: 0;
            margin: 0;
            span {
                margin-bottom: 2px;
                margin-left: 2px;
            }
        }
    }
    // .btn,.__button{
    //     background:var(--secondary-bg);
    //     padding: 5px;
    //     border-radius: 50px;
    //     display: flex;
    ._added {
        color: #000 !important;
    }
    .add {
        margin: 0 10px;
        color: var(--button-text-color);
        // color: #fff;
    }
    span {
        color: var(--button-text-color);
        // color: #fff;
    }
    .no-add {
        font-size: 14px;
        font-weight: 500;
    }
    .notify_me {
        max-height: 28px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 600;
        border-radius: 3px;
        background: var(--alternative);
        color: #fff;
    }
    // .button,.cart__button{
    //     display: flex;
    //     justify-content: flex-end;
    //     align-items: center;
    //     overflow: hidden;
    //     // height: 32px;
    //     // margin-right: 10px;
    //     // color: var(--primary-txt);
    //     // margin: 2px 10px;
    //     // padding: 1px 0;
    //     // border-radius: 5px;
    //     ion-icon{
    //         color: var(--primary-txt);
    //     }
    // }
}

// Detail page
.notify-me {
    font-weight: 600;
    border-radius: 3px;
    background: var(--alternative);
    color: #fff
}

// }
.choosed {
    background: var(--secondary-bg);
    color: var(--button-text-color);
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
}

ion-button,
ion-badge {
    background: var(--secondary-bg);
    color: var(--button-text-color);
}

// ion-badge{
//   background: var(--secondary-bg);
// }
.p5 {
    padding: 5px;
}

.mb5 {
    margin-bottom: 5px;
}

.bg-gray {
    background: var(--gray-bg);
}

.main-width1 {
    width: 95%;
    margin: 0 auto;
    padding: 20px 0;
}

// .form-input[readonly="true"], .form-input[ng-reflect-readonly=true], 
// .form-input input[readonly="true"], .form-input input[readonly]  {
//     background: #ddd;
// }
// .no-bg {
//   --background-hover: transparent !important;
//   --background-focused: transparent !important;
//   .box-label {
//     color: #000 !important;
//   }
// }
.focused {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid var(--secondary-bg) !important;
    mask-border-width: 2px;
    transition-duration: .15s;
}

.head-container {
    margin: auto;
    padding: 0 5px;
    img {
        height: 60px;
    }
    .userProfileIcon {
        float: right;
        padding: 5px;
    }
}

.head-title {
    font-size: 18px;
    padding-inline: 10px;
}

.container-cls {
    margin: auto;
    padding: 0 15px;
}

// @media screen and (min-width: 992px) {
.page_section {
    margin: 0 auto;
    // max-width: 1366px;
    padding: 0 60px 0 60px;
}

.text-center {
    text-align: center;
}

.white-text {
    color: var(--white);
}

.free__item {
    position: absolute;
    left: -19px;
    transform: rotate(-45deg);
    top: 5px;
    background: var(--alternative);
    width: 80px;
    padding: 1px 10px 1px 21px;
    .free__item__text {
        color: var(--white);
        font-size: 11px;
    }
}

.oops-icon {
    // position: absolute; 
    // left: 0; 
    // right: 0; 
    // top:20%;
    // margin-left: auto; 
    // margin-right: auto; 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    ion-icon {
        font-size: 350px;
    }
}

.mobile__unique__category {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding: 5px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    margin-bottom: 5px;
    .category__item {
        flex: 0 0 13%;
        .category__img {
            border: 1px solid var(--border);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;
            width: 54px;
            background: var(--secondary-bg);
            img {
                height: 48px;
            }
        }
        .active-category-name {
            color: var(--secondary-bg);
        }
        .category__name {
            text-align: center;
            font-size: 12px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
    }
}

@media screen and (min-width:768px) {
    img.ng-lazyloaded {
        animation: fadein .7s;
        transition: opacity .4s ease-in-out, transform .4s ease-in-out;
        // -webkit-animation-name: fadeInLeft;
        // animation-name: fadeInLeft;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        // width: 50%;
    }
    to {
        opacity: 1;
        // width: 100%;
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        //  width: 50%;
        //  -webkit-transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        //  width: 100%;
        //  -webkit-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        //  width: 50%;
        //  transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        //  width: 100%;
        //  transform: translateX(0);
    }
}

.disabled {
    opacity: 0.7;
}

.tostifyy {
    position: fixed;
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 999px);
    transform: translate3d(0, 0, 999px);
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    box-sizing: border-box;
    .tostify {
        height: 50px;
        margin: 20px 0;
        transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
        transform: translateX(0px);
        opacity: 1;
        padding: 10px 20px;
        border-radius: 3px;
        // box-shadow:  1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
        .progress {
            animation-duration: 1500ms;
            animation-play-state: paused;
            opacity: 1;
        }
        ion-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 19px;
            color: #fff;
            cursor: pointer;
        }
    }
}

.success__alert {
    width: 320px;
    bottom: 50px;
    right: 50px;
    .tostify {
        background-color: var(--secondary-bg);
    }
    .success-txt {
        font-size: 13px;
        .sucss {
            padding-left: 15px;
            color: #008000;
            font-weight: 600;
        }
    }
}

.tostify__alert {
    width: 220px;
    bottom: 0;
    right: 50px;
    .tostify {
        background-color: red;
        span {
            font-size: 13px;
            color: #fff;
        }
    }
}

@media screen and (max-width:768px) {
    .tostify__alert {
        bottom: 65px !important;
        right: 80px !important;
    }
}

.shaking {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

// "outputPath": "dist/app/browser",
.oops-icon1 {
    text-align: center;
    width: 100%;
    height: calc(100% - 133px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    ion-icon {
        font-size: 160px;
        text-align: center;
        position: relative;
    }
    .oops-text {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
    }
}

.sub_now {
    margin: 5px 0 0 0;
    background: var(--secondary-bg) !important;
    // color: var(--primary-bg);
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}

@media screen and ( max-width:768px) {
    .sub_now {
        font-size: 12px !important;
    }
}

.location__warning {
    gap: 10px;
    background: #ff00001f;
    padding: 6px;
    color: red;
    ion-icon,
    p {
        color: red;
    }
}

.errors {
    font-size: 11px;
    color: red;
    margin-top: -5px;
    margin-bottom: 5px;
}

.error {
    font-size: 10px;
    color: red;
    background: #ff000014;
    padding-left: 10px;
}

.action-button {
    text-transform: capitalize;
}

@media screen and (max-width:768px) {
    .action-button {
        width: 85%;
        margin: 0 auto;
    }
}

@media screen and (min-width:768px) {
    .action-button {
        font-size: 16px;
        font-weight: 500;
        border-radius: 3px;
        height: 50px;
        margin: 0 10px;
    }
    .myAccount-titles {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--border);
        margin: 0;
        padding: 12px;
        font-size: 16px;
    }
}

form {
    .input__title {
        font-size: 13px;
        color: #000;
        opacity: 0.7;
        margin: 0;
        font-weight: 500;
    }
    .custom-input {
        width: 100%;
        margin: 7px 0;
        border: 1px solid;
        border-color: var(--border);
        border-radius: 3px;
        font-size: 14px;
        ion-select {
            padding-right: 10px;
        }
    }
    .divider {
        margin: 20px 0;
        border-bottom: 1px solid var(--border);
    }
}

.focus:focus {
    outline: 1px solid transparent;
    outline-offset: 2px;
}

//Header component
.drop__down__header {
    position: relative;
    float: left;
    width: 100%;
    .category__img {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        padding: 5px;
        background: var(--secondary-bg);
        margin: 0 auto;
        img {
            vertical-align: middle;
        }
    }
    .childs {
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    }
}

.location-modal {
    --width: 530px;
    --height: 198px;
    padding-top: 71px;
    align-items: start;
    padding-right: 515px;
    --border-radius: 3px !important;
}

.location-modal-locations {
    --width: 530px;
    --height: 272px;
    padding-top: 71px;
    align-items: start;
    padding-right: 515px;
    --border-radius: 3px !important;
}